import { createVNode as _createVNode, toDisplayString as _toDisplayString, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "container popup-container",
  style: {"padding-top":"100px"}
}
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "row edit-row" }
const _hoisted_4 = {
  ref: "map",
  style: {"width":"100vw","height":"70vh"}
}
const _hoisted_5 = { class: "row edit-row" }
const _hoisted_6 = { class: "col-md-4" }
const _hoisted_7 = { class: "col-md-4" }
const _hoisted_8 = { class: "col-md-4" }
const _hoisted_9 = { class: "row edit-row popup-two-buttons" }
const _hoisted_10 = { class: "col-12" }
const _hoisted_11 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _createVNode("div", _hoisted_4, null, 512)
      ]),
      _createVNode("div", _hoisted_5, [
        _createVNode("div", _hoisted_6, [
          _createVNode("label", null, _toDisplayString(_ctx.$t('product.addressSearch')), 1),
          _createVNode("input", {
            required: "",
            type: "text",
            class: "bookit-input product-input",
            placeholder: _ctx.$t('placeholder.writeAddress'),
            ref: "address"
          }, null, 8, ["placeholder"])
        ]),
        _createVNode("div", _hoisted_7, [
          _createVNode("label", null, _toDisplayString(_ctx.$t('product.longitude')), 1),
          _withDirectives(_createVNode("input", {
            type: "text",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.clonedProduct.longitude = $event)),
            class: "bookit-input product-input",
            placeholder: _ctx.$t('placeholder.longitude'),
            onKeypress: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isNegativeDecimalNumber($event))),
            onChange: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.latLngChanged && _ctx.latLngChanged(...args)))
          }, null, 40, ["placeholder"]), [
            [_vModelText, _ctx.clonedProduct.longitude]
          ])
        ]),
        _createVNode("div", _hoisted_8, [
          _createVNode("label", null, _toDisplayString(_ctx.$t('product.latitude')), 1),
          _withDirectives(_createVNode("input", {
            type: "text",
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.clonedProduct.latitude = $event)),
            class: "bookit-input product-input",
            placeholder: _ctx.$t('placeholder.latitude'),
            onKeypress: _cache[5] || (_cache[5] = ($event: any) => (_ctx.isNegativeDecimalNumber($event))),
            onChange: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.latLngChanged && _ctx.latLngChanged(...args)))
          }, null, 40, ["placeholder"]), [
            [_vModelText, _ctx.clonedProduct.latitude]
          ])
        ])
      ]),
      _createVNode("div", _hoisted_9, [
        _createVNode("div", _hoisted_10, [
          _createVNode("button", {
            class: "save-button-wrapper popup-bookit-button w-100",
            onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args))),
            type: "button"
          }, _toDisplayString(_ctx.$t('button.save')), 1)
        ]),
        _createVNode("div", _hoisted_11, [
          _createVNode("button", {
            class: "close-button-wrapper popup-bookit-button w-100",
            onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args))),
            type: "button"
          }, _toDisplayString(_ctx.$t('button.close')), 1)
        ])
      ])
    ])
  ]))
}