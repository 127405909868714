
import { ref, computed, onMounted, defineComponent, nextTick } from 'vue';
import { generalStore } from '@/store';
import useProduct from '@/modules/useProduct';
import { Product } from '@/models/Product';
import { SaveProductAddressRequest } from '@/models/RequestInterfaces';
import router from '@/router';
import { api } from '@/services/Api';
import swal from 'sweetalert2';
import { useI18n } from 'vue-i18n';
import {} from 'google-maps';
import { Loader } from '@googlemaps/js-api-loader';

export default defineComponent({
    name: 'LocationMap',
    props: {
        productId: {
            type: String
        },
        fromWizzard: {
            type: String,
            default: 'false'
        }
    },
    async setup(props: any) {
        const { t } = useI18n();
        const product = computed<Product>(() => generalStore.getters.getNewProduct);
        const clonedProduct = ref(JSON.parse(JSON.stringify(product.value)));
        const map = ref();
        const address = ref();
        const googleMap = ref();
        const googleAutocomplete = ref();
        const { getProduct } = useProduct();

        const marker = ref();

        const loader = new Loader({
            apiKey: generalStore.getters.googleMapsApiKey,
            version: 'weekly',
            language: generalStore.getters.currentLanguage,
            libraries: ['places']
        });

        const mapOptions = {
            center: { lat: generalStore.getters.countryLatitude, lng: generalStore.getters.countryLongitude }, // By default Israel coordinates
            zoom: 10
        };

        const autoCompleteOptions = {
            componentRestrictions: { country: generalStore.getters.countryCode }
        };

        const setProductLocation = async () => {
            const geocoder = new window.google.maps.Geocoder();
            return geocoder.geocode({ location: marker.value.position }, async function(results: any, status: any) {
                clonedProduct.value.longitude = marker.value.position.lng();
                clonedProduct.value.latitude = marker.value.position.lat();
            });
        }

        const latLngChanged = async () => {
            const position = { lat: Number(clonedProduct.value.latitude), lng: Number(clonedProduct.value.longitude) };
            if (!marker.value) {
                marker.value = new window.google.maps.Marker({
                    position: position,
                    map: googleMap.value
                });
            } else {
                marker.value.setPosition(position);
            }
            googleMap.value.setCenter(position);
            setProductLocation();
        };

        onMounted(async () => {
            await loader.load();

            if (props.productId) await getProduct(props.productId);
            clonedProduct.value = JSON.parse(JSON.stringify(product.value));

            googleMap.value = new window.google.maps.Map(map.value, mapOptions);
            window.google.maps.event.addListener(googleMap.value, 'click', function(event: any) {
                if (!marker.value) {
                    marker.value = new window.google.maps.Marker({
                        position: event.latLng,
                        map: googleMap.value
                    });
                } else {
                    marker.value.setPosition(event.latLng);
                }
                setProductLocation();
            });

            googleAutocomplete.value = new window.google.maps.places.Autocomplete(address.value, autoCompleteOptions);
            window.google.maps.event.addListener(googleAutocomplete.value, 'place_changed', () => {
                const place = googleAutocomplete.value.getPlace();
                if (clonedProduct.value && place && place.geometry) {
                    clonedProduct.value.latitude = place.geometry?.location.lat();
                    clonedProduct.value.longitude = place.geometry?.location.lng();
                    if (!marker.value) {
                        marker.value = new window.google.maps.Marker({
                            position: place.geometry?.location,
                            map: googleMap.value
                        });
                    } else {
                        marker.value.setPosition(place.geometry?.location);
                    }
                    googleMap.value.setCenter(place.geometry?.location);
                }
            });

            if (clonedProduct.value.latitude && clonedProduct.value.longitude) {
                const position = { lat: clonedProduct.value.latitude, lng: clonedProduct.value.longitude };
                marker.value = new window.google.maps.Marker({
                    position: position,
                    map: googleMap.value
                });
                googleMap.value.setCenter(position);
                googleMap.value.setZoom(15);
            } else {
                navigator.geolocation.getCurrentPosition(
                    position => {
                        googleMap.value.setCenter({ lat: position.coords.latitude, lng: position.coords.longitude });
                        googleMap.value.setZoom(15);
                    },
                    error => {
                        console.log(error.message);
                    }
                );
            }
        });

        const close = () => router.go(-1);

        const save = async () => {
            if (!marker.value) {
                const errorMessage = !marker.value ? t('alert.pleaseSetMarker') : t('alert.pleaseFillAddress');
                swal.fire({
                    icon: 'info',
                    title: t('error-pop-up.oops'),
                    text: errorMessage
                });
                return;
            }

            swal.showLoading();

            const request: SaveProductAddressRequest = {
                productId: clonedProduct.value.id,
                longitude: clonedProduct.value.longitude,
                latitude: clonedProduct.value.latitude,
                fromWizzard: props.fromWizzard == 'true'
            };

            const response = await api.saveProductAddress(request);
            if (response.errorMessage) swal.fire({ icon: 'error', title: t('error-pop-up.oops'), text: response.errorMessage });
            else {
                generalStore.commit('setProduct', clonedProduct.value);
                swal.close();
                close();
            }
        };

        return {
            clonedProduct,
            product,
            map,
            marker,
            address,
            save,
            close,
            latLngChanged
        };
    }
});
